import { CSSObject } from "@emotion/react"
import { Menu, MenuItem, styled } from "@mui/material"
import { typography } from "pitch45-common/theme/typography"
import React, { useState } from "react"
import { color } from "../../theme/color"
import { unitSpacing } from "../../theme/spacing"

export const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: unitSpacing.quarter,
    backgroundColor: theme.dark ? color.palette.slateGrey : undefined,
    borderRadius: "10px",
    "& .MuiMenu-list": {
      borderRadius: "10px",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: theme.dark ? color.palette.slateGrey : theme.colors.border,
    },
    "& .MuiMenuItem-root": {
      ...typography.regular,
      color: theme.colors.text,
      "&:active": {
        color: color.primary,
        backgroundColor: theme.dark ? color.palette.offWhite : color.palette.offWhite,
      },
      "&:hover": {
        backgroundColor: color.menuItemHover,
        ...typography.light,
      },
    },
  },
}))

export function useMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  function renderMenuItems(
    menuItems: {
      label: string
      onClick?: () => void
      labelStyle?: CSSObject
      customMenuElement?: React.ReactElement
    }[],
  ) {
    return (
      menuItems
        // allow null values in the array but remove them here
        .filter((x) => x)
        .map((item) => {
          const onClickAction = (event: React.MouseEvent<HTMLElement>) => {
            handleClose(event)
            item.onClick?.()
          }

          return (
            item.customMenuElement || (
              <MenuItem
                key={item.label}
                onClick={onClickAction}
                css={{ "&&.MuiMenuItem-root": { ...item.labelStyle } }}
              >
                {item.label}
              </MenuItem>
            )
          )
        })
    )
  }

  return { handleClick, handleClose, anchorEl, renderMenuItems, open }
}
