import React from "react"
import Add from "@mui/icons-material/Add"
import Apps from "@mui/icons-material/Apps"
import Archive from "@mui/icons-material/Archive"
import ArtTrack from "@mui/icons-material/ArtTrack"
import AttachFile from "@mui/icons-material/AttachFile"
import AutoGraph from "@mui/icons-material/AutoGraph"
import AutoMode from "@mui/icons-material/AutoMode"
import Bookmark from "@mui/icons-material/Bookmark"
import BookmarkBorder from "@mui/icons-material/BookmarkBorder"
import Chat from "@mui/icons-material/Chat"
import Cancel from "@mui/icons-material/Cancel"
import ChevronLeft from "@mui/icons-material/ChevronLeft"
import ChevronRight from "@mui/icons-material/ChevronRight"
import Check from "@mui/icons-material/Check"
import CheckCircle from "@mui/icons-material/CheckCircle"
import Close from "@mui/icons-material/Close"
import ClosedCaption from "@mui/icons-material/ClosedCaption"
import ClosedCaptionDisabled from "@mui/icons-material/ClosedCaptionDisabled"
import Contacts from "@mui/icons-material/Contacts"
import ContentCopy from "@mui/icons-material/ContentCopy"
import Dashboard from "@mui/icons-material/Dashboard"
import Description from "@mui/icons-material/Description"
import Email from "@mui/icons-material/Email"
import Edit from "@mui/icons-material/Edit"
import ExpandMore from "@mui/icons-material/ExpandMore"
import ExpandLess from "@mui/icons-material/ExpandLess"
import Face from "@mui/icons-material/Face"
import FileCopy from "@mui/icons-material/FileCopy"
import Group from "@mui/icons-material/Group"
import Groups from "@mui/icons-material/Groups"
import Link from "@mui/icons-material/Link"
import Lock from "@mui/icons-material/Lock"
import Logout from "@mui/icons-material/Logout"
import MoreVert from "@mui/icons-material/MoreVert"
import MoreHoriz from "@mui/icons-material/MoreHoriz"
import OpenInFull from "@mui/icons-material/OpenInFull"
import Password from "@mui/icons-material/Password"
import Payments from "@mui/icons-material/Payments"
import PlayCircle from "@mui/icons-material/PlayCircle"
import PlaylistAdd from "@mui/icons-material/PlaylistAdd"
import PlaylistPlay from "@mui/icons-material/PlaylistPlay"
import Photo from "@mui/icons-material/Photo"
import PhotoLibrary from "@mui/icons-material/PhotoLibrary"
import Public from "@mui/icons-material/Public"
import RequestQuote from "@mui/icons-material/RequestQuote"
import Slideshow from "@mui/icons-material/Slideshow"
import Stars from "@mui/icons-material/Stars"
import Today from "@mui/icons-material/Today"
import VerifiedUser from "@mui/icons-material/VerifiedUser"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import VolumeOff from "@mui/icons-material/VolumeOff"
import VolumeUp from "@mui/icons-material/VolumeUp"
import VpnLock from "@mui/icons-material/VpnLock"
import Warning from "@mui/icons-material/Warning"
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline"
import DragIndicator from "@mui/icons-material/DragIndicator"
import { fontSizing, iconSizing } from "../../theme"
import RadioButtonChecked from "@mui/icons-material/RadioButtonChecked"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"
import EditNote from "@mui/icons-material/EditNote"
import Delete from "@mui/icons-material/Delete"
import MdiIcon from "@mdi/react"
import { mdiStarBoxMultiple } from "@mdi/js"
import PlayCircleOutline from "@mui/icons-material/PlayCircleOutline"
import CloudDownloadOutlined from "@mui/icons-material/CloudDownloadOutlined"
import ZoomIn from "@mui/icons-material/ZoomIn"
import ZoomOut from "@mui/icons-material/ZoomOut"
import ZoomOutMap from "@mui/icons-material/ZoomOutMap"
import { CSSObject } from "@emotion/react"
import PlayArrow from "@mui/icons-material/PlayArrow"
import Pause from "@mui/icons-material/Pause"
import SkipNext from "@mui/icons-material/SkipNext"
import Replay from "@mui/icons-material/Replay"
import Business from "@mui/icons-material/Business"
import Approval from "@mui/icons-material/Approval"
import Assignment from "@mui/icons-material/Assignment"
import Preview from "@mui/icons-material/Preview"
import Settings from "@mui/icons-material/Settings"
import Checklist from "@mui/icons-material/Checklist"
import People from "@mui/icons-material/People"
import Schedule from "@mui/icons-material/Schedule"
import PhoneIphone from "@mui/icons-material/PhoneIphone"

// please maintain alphabetical order for readability
export enum Icons {
  Add = "add",
  Apps = "apps",
  Approval = "approval",
  ArtTrack = "artTrack",
  Archive = "archive",
  Assignment = "assignment",
  AttachFile = "attachFile",
  AutoGraph = "autoGraph",
  AutoMode = "autoMode",
  Bookmark = "bookmark",
  BookmarkBorder = "bookmarkBorder",
  Business = "business",
  Cancel = "cancel",
  Chat = "chat",
  Check = "check",
  CheckCircle = "checkCircle",
  Checklist = "checklist",
  ChevronLeft = "chevronLeft",
  ChevronRight = "chevronRight",
  Close = "close",
  ClosedCaption = "closedCaption",
  ClosedCaptionDisabled = "closedCaptionDisabled",
  Contacts = "contacts",
  ContentCopy = "contentCopy",
  Dashboard = "dashboard",
  Delete = "delete",
  Description = "description",
  Download = "cloudDownloadOutline",
  DragIndicator = "dragIndicator",
  Edit = "edit",
  EditNote = "editNote",
  Email = "email",
  ExpandLess = "expandLess",
  ExpandMore = "expandMore",
  Face = "face",
  FileCopy = "fileCopy",
  Group = "group",
  Groups = "groups",
  Link = "link",
  Lock = "lock",
  Logout = "logout",
  KeyboardArrowDown = "keyboardArrowDown",
  MoreVert = "moreVert",
  MoreHoriz = "moreHoriz",
  OpenInFull = "openInFull",
  Password = "password",
  Pause = "pause",
  Payments = "payments",
  People = "people",
  PlayArrow = "playArrow",
  PlayCircle = "playCircle",
  PlayCircleOutline = "playCircleOutline",
  PlaylistAdd = "playlistAdd",
  PlaylistPlay = "playlistPlay",
  PhoneIphone = "phoneIphone",
  Photo = "photo",
  PhotoLibrary = "photoLibrary",
  Preview = "preview",
  Public = "public",
  RadioButtonChecked = "radioButtonChecked",
  RemoveCircleOutline = "removeCircleOutline",
  Replay = "replay",
  RequestQuote = "requestQuote",
  Schedule = "schedule",
  Settings = "settings",
  SkipNext = "skipNext",
  Slideshow = "slideshow",
  Stars = "stars",
  StarBoxMultiple = "starBoxMultiple",
  Today = "today",
  VerifiedUser = "verifiedUser",
  Visibility = "visibility",
  VisibilityOff = "visibilityOff",
  VolumeOff = "volumeOff",
  VolumeUp = "volumeUp",
  VpnLock = "vpnLock",
  Warning = "Warning",
  ZoomIn = "ZoomIn",
  ZoomOut = "ZoomOut",
  ZoomOutMap = "ZoomOutMap",
}

export enum Loop45Icons {
  Share = "Share",
  PlayLogo = "PlayLogo",
  Unavailable = "Unavailable",
  Analytics = "Analytics",
  LineChart = "LineChart",
  BarChart = "BarChart",
  Spotlight = "Spotlight",
  Video = "Video",
  Calendar = "Calendar",
  ChevronRight = "ChevronRight",
  Programs = "Programs",
  Trashcan = "Trashcan",
  Safeguard = "Safeguard",
  App = "App",
  VideoCreationBold = "VideoCreationBold",
  ChevronDown = "ChevronDown",
  ChevronUp = "ChevronUp",
  InviteMembers = "InviteMembers",
  Settings = "Settings",
  Home = "Home",
  Billing = "Billing",
  Admins = "Admins",
  Members = "Members",
  AddImage = "AddImage",
  AddImagePerson = "AddImagePerson",
  About = "About",
  Edit = "Edit",
  ReactOutlined = "ReactOutlined",
  ReactFilled = "ReactFilled",
  Mobile45 = "Mobile45",
  LifePreserver = "LifePreserver",
  OrgAdmin = "OrgAdmin",
  OrgOwner = "OrgOwner",
  OrgMember = "OrgMember",
  GroupAdmin = "GroupAdmin",
  GroupCreator = "GroupCreator",
  GroupMember = "GroupMember",
  AddMember = "AddMember",
  Invite = "Invite",
  Lock = "Lock",
  Logout = "Logout",
  EditProfile = "EditProfile",
  Password = "Password",
  AllSet = "AllSet",
  BigIdea = "BigIdea",
  Outstanding = "Outstanding",
  GreatJob = "GreatJob",
  LetsConnect = "LetsConnect",
  EmailSupport = "EmailSupport",
  ManageAccount = "ManageAccount",
  BusinessPlan = "BusinessPlan",
}

interface Loop45IconProps {
  size?: "tiny" | "small" | "large" | "inherit" | "medium" | "colossal" | "huge" | "massive"
  unicodePoint: string
}

const Loop45Icon = ({ unicodePoint, size, ...rest }: Loop45IconProps) => {
  const style = {
    fontFamily: "Loop45",
    fontSize: iconSizing.default,
    color: (rest as unknown as any).sx?.color,
  }

  switch (size) {
    case "tiny":
      style.fontSize = iconSizing.tiny
      break
    case "small":
      style.fontSize = iconSizing.tiny
      break
    case "medium":
      style.fontSize = iconSizing.medium
      break
    case "large":
      style.fontSize = iconSizing.large
      break
    case "huge":
      style.fontSize = iconSizing.huge
      break
    case "massive":
      style.fontSize = iconSizing.massive
      break
    case "colossal":
      style.fontSize = iconSizing.colossal
      break
    case "inherit":
      style.fontSize = "inherit" as any
      break
    default:
      style.fontSize = iconSizing.default
  }

  return (
    <span {...rest} css={style}>
      {unicodePoint}
    </span>
  )
}

export interface IconProps {
  icon: Icons | Loop45Icons
  size?: "tiny" | "small" | "large" | "inherit" | "medium" | "colossal" | "huge" | "massive"
  onClick?: () => void
  color?: string
  sx?: CSSObject
  ref?: React.Ref<any>
}

export function Icon(props: IconProps) {
  const { icon, color, ...rest } = props
  let { size = "small" } = props

  if (color) {
    rest.sx = { color }
  }

  // handle Loop45 icons before material icons
  switch (icon) {
    case Loop45Icons.Share:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xea2f;" />
    case Loop45Icons.PlayLogo:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xea17;" />
    case Loop45Icons.Unavailable:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe90c;" />
    case Loop45Icons.Analytics:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xea12;" />
    case Loop45Icons.LineChart:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xea04;" />
    case Loop45Icons.BarChart:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe9c1;" />
    case Loop45Icons.Spotlight:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xea32;" />
    case Loop45Icons.Video:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xea40;" />
    case Loop45Icons.Calendar:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe9c3;" />
    case Loop45Icons.ChevronRight:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe981;" />
    case Loop45Icons.Programs:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xea19;" />
    case Loop45Icons.Trashcan:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe9e9;" />
    case Loop45Icons.Safeguard:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe993;" />
    case Loop45Icons.App:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe994;" />
    case Loop45Icons.VideoCreationBold:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe9e6;" />
    case Loop45Icons.ChevronDown:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe933;" />
    case Loop45Icons.ChevronUp:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe934;" />
    case Loop45Icons.InviteMembers:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe9b4;" />
    case Loop45Icons.Settings:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xea2d;" />
    case Loop45Icons.Home:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe919;" />
    case Loop45Icons.Billing:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe9c2;" />
    case Loop45Icons.Admins:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe9b9;" />
    case Loop45Icons.Members:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe924;" />
    case Loop45Icons.AddImage:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe9b5;" />
    case Loop45Icons.AddImagePerson:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xea15;" />
    case Loop45Icons.About:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe9eb;" />
    case Loop45Icons.Edit:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe9f0;" />
    case Loop45Icons.ReactOutlined:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe920;" />
    case Loop45Icons.ReactFilled:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe91f;" />
    case Loop45Icons.Mobile45:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe994;" />
    case Loop45Icons.LifePreserver:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe923;" />
    case Loop45Icons.OrgAdmin:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe930;" />
    case Loop45Icons.OrgOwner:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe932;" />
    case Loop45Icons.OrgMember:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe931;" />
    case Loop45Icons.GroupAdmin:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe92e;" />
    case Loop45Icons.GroupCreator:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe92d;" />
    case Loop45Icons.GroupMember:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe92f;" />
    case Loop45Icons.AddMember:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe936;" />
    case Loop45Icons.Invite:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe935;" />
    case Loop45Icons.Lock:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe928;" />
    case Loop45Icons.Logout:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xea08;" />
    case Loop45Icons.EditProfile:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe9f2;" />
    case Loop45Icons.Password:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xea13;" />
    case Loop45Icons.AllSet:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe910;" />
    case Loop45Icons.BigIdea:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe912;" />
    case Loop45Icons.Outstanding:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe91e;" />
    case Loop45Icons.GreatJob:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe91a;" />
    case Loop45Icons.LetsConnect:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe91c;" />
    case Loop45Icons.EmailSupport:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe92a;" />
    case Loop45Icons.ManageAccount:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe941;" />
    case Loop45Icons.BusinessPlan:
      return <Loop45Icon {...rest} size={size} unicodePoint="&#xe942;" />
  }

  // material community icons
  // for prop docs see: https://github.com/Templarian/MaterialDesign-React#icon-icon
  switch (icon) {
    case Icons.StarBoxMultiple:
      return (
        <MdiIcon
          path={mdiStarBoxMultiple}
          size={`${iconSizing[size] ?? iconSizing.default}px`}
          color={color}
        />
      )
  }

  // size fix for material icons
  if (size === "tiny") {
    size = "small"
    rest.sx = { ...rest.sx, fontSize: fontSizing.small }
  } else if (size === "colossal") {
    size = "large"
    rest.sx = { ...rest.sx, fontSize: fontSizing.colossal }
  } else if (size === "huge") {
    size = "large"
    rest.sx = { ...rest.sx, fontSize: fontSizing.huge }
  } else if (size === "massive") {
    size = "large"
    rest.sx = { ...rest.sx, fontSize: fontSizing.massive }
  }
  /* eslint-enable dot-notation */

  // please maintain alphabetical order for readability
  switch (icon) {
    case Icons.Add:
      return <Add {...rest} fontSize={size} />
    case Icons.Approval:
      return <Approval {...rest} fontSize={size} />
    case Icons.Apps:
      return <Apps {...rest} fontSize={size} />
    case Icons.Archive:
      return <Archive {...rest} fontSize={size} />
    case Icons.ArtTrack:
      return <ArtTrack {...rest} fontSize={size} />
    case Icons.Assignment:
      return <Assignment {...rest} fontSize={size} />
    case Icons.AttachFile:
      return <AttachFile {...rest} fontSize={size} />
    case Icons.AutoGraph:
      return <AutoGraph {...rest} fontSize={size} />
    case Icons.AutoMode:
      return <AutoMode {...rest} fontSize={size} />
    case Icons.Bookmark:
      return <Bookmark {...rest} fontSize={size} />
    case Icons.BookmarkBorder:
      return <BookmarkBorder {...rest} fontSize={size} />
    case Icons.Business:
      return <Business {...rest} fontSize={size} />
    case Icons.Cancel:
      return <Cancel {...rest} fontSize={size} />
    case Icons.Chat:
      return <Chat {...rest} fontSize={size} />
    case Icons.Check:
      return <Check {...rest} fontSize={size} />
    case Icons.CheckCircle:
      return <CheckCircle {...rest} fontSize={size} />
    case Icons.Checklist:
      return <Checklist {...rest} fontSize={size} />
    case Icons.ChevronLeft:
      return <ChevronLeft {...rest} fontSize={size} />
    case Icons.ChevronRight:
      return <ChevronRight {...rest} fontSize={size} />
    case Icons.Close:
      return <Close {...rest} fontSize={size} />
    case Icons.ClosedCaption:
      return <ClosedCaption {...rest} fontSize={size} />
    case Icons.ClosedCaptionDisabled:
      return <ClosedCaptionDisabled {...rest} fontSize={size} />
    case Icons.Contacts:
      return <Contacts {...rest} fontSize={size} />
    case Icons.ContentCopy:
      return <ContentCopy {...rest} fontSize={size} />
    case Icons.Dashboard:
      return <Dashboard {...rest} fontSize={size} />
    case Icons.Delete:
      return <Delete {...rest} fontSize={size} />
    case Icons.Description:
      return <Description {...rest} fontSize={size} />
    case Icons.Download:
      return <CloudDownloadOutlined {...rest} fontSize={size} />
    case Icons.DragIndicator:
      return <DragIndicator {...rest} fontSize={size} />
    case Icons.Edit:
      return <Edit {...rest} fontSize={size} />
    case Icons.EditNote:
      return <EditNote {...rest} fontSize={size} />
    case Icons.Email:
      return <Email {...rest} fontSize={size} />
    case Icons.ExpandLess:
      return <ExpandLess {...rest} fontSize={size} />
    case Icons.ExpandMore:
      return <ExpandMore {...rest} fontSize={size} />
    case Icons.Face:
      return <Face {...rest} fontSize={size} />
    case Icons.FileCopy:
      return <FileCopy {...rest} fontSize={size} />
    case Icons.Group:
      return <Group {...rest} fontSize={size} />
    case Icons.Groups:
      return <Groups {...rest} fontSize={size} />
    case Icons.Link:
      return <Link {...rest} fontSize={size} />
    case Icons.Lock:
      return <Lock {...rest} fontSize={size} />
    case Icons.Logout:
      return <Logout {...rest} fontSize={size} />
    case Icons.KeyboardArrowDown:
      return <KeyboardArrowDown {...rest} fontSize={size} />
    case Icons.MoreVert:
      return <MoreVert {...rest} fontSize={size} />
    case Icons.MoreHoriz:
      return <MoreHoriz {...rest} fontSize={size} />
    case Icons.OpenInFull:
      return <OpenInFull {...rest} fontSize={size} />
    case Icons.Password:
      return <Password {...rest} fontSize={size} />
    case Icons.Pause:
      return <Pause {...rest} fontSize={size} />
    case Icons.Payments:
      return <Payments {...rest} fontSize={size} />
    case Icons.People:
      return <People {...rest} fontSize={size} />
    case Icons.PlayArrow:
      return <PlayArrow {...rest} fontSize={size} />
    case Icons.PlayCircle:
      return <PlayCircle {...rest} fontSize={size} />
    case Icons.PlayCircleOutline:
      return <PlayCircleOutline {...rest} fontSize={size} />
    case Icons.PlaylistAdd:
      return <PlaylistAdd {...rest} fontSize={size} />
    case Icons.PlaylistPlay:
      // This icon has more spacing to the right and doesn't not seem centered without the translateX prop
      rest.sx = { ...rest.sx, transform: "translateX(2px)" }
      return <PlaylistPlay {...rest} fontSize={size} />
    case Icons.PhoneIphone:
      return <PhoneIphone {...rest} fontSize={size} />
    case Icons.Photo:
      return <Photo {...rest} fontSize={size} />
    case Icons.PhotoLibrary:
      return <PhotoLibrary {...rest} fontSize={size} />
    case Icons.Preview:
      return <Preview {...rest} fontSize={size} />
    case Icons.Public:
      return <Public {...rest} fontSize={size} />
    case Icons.RadioButtonChecked:
      return <RadioButtonChecked {...rest} fontSize={size} />
    case Icons.RemoveCircleOutline:
      return <RemoveCircleOutline {...rest} fontSize={size} />
    case Icons.Replay:
      return <Replay {...rest} fontSize={size} />
    case Icons.RequestQuote:
      return <RequestQuote {...rest} fontSize={size} />
    case Icons.Schedule:
      return <Schedule {...rest} fontSize={size} />
    case Icons.Settings:
      return <Settings {...rest} fontSize={size} />
    case Icons.SkipNext:
      return <SkipNext {...rest} fontSize={size} />
    case Icons.Slideshow:
      return <Slideshow {...rest} fontSize={size} />
    case Icons.Stars:
      return <Stars {...rest} fontSize={size} />
    case Icons.Today:
      return <Today {...rest} fontSize={size} />
    case Icons.VerifiedUser:
      return <VerifiedUser {...rest} fontSize={size} />
    case Icons.Visibility:
      return <Visibility {...rest} fontSize={size} />
    case Icons.VisibilityOff:
      return <VisibilityOff {...rest} fontSize={size} />
    case Icons.VolumeOff:
      return <VolumeOff {...rest} fontSize={size} />
    case Icons.VolumeUp:
      return <VolumeUp {...rest} fontSize={size} />
    case Icons.VpnLock:
      return <VpnLock {...rest} fontSize={size} />
    case Icons.Warning:
      return <Warning {...rest} fontSize={size} />
    case Icons.ZoomIn:
      return <ZoomIn {...rest} fontSize={size} />
    case Icons.ZoomOut:
      return <ZoomOut {...rest} fontSize={size} />
    case Icons.ZoomOutMap:
      return <ZoomOutMap {...rest} fontSize={size} />
    default:
      return null
  }
}

export default Icon
