import { uniqBy } from "lodash-es"
import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withPitchStore } from "./pitch-store"
import { withSessionStore } from "./session-store"
import { StudioTag, StudioTagModel, StudioTagType } from "../models/studio-tag"
import { Pitch, PitchStatus } from "../models/pitch"

export const StudioStoreModel = types
  .model("Studio")
  .props({
    tags: types.map(StudioTagModel),
    selectedTags: types.array(types.safeReference(StudioTagModel, { acceptsUndefined: false })),
  })
  .extend(withEnvironment)
  .extend(withPitchStore)
  .extend(withSessionStore)
  .actions((self) => ({
    updateTags(pitches: Pitch[]) {
      const entities = pitches
        .filter((p) => p.entity.id !== self.sessionStore.currentUser?.id)
        .map((p) => p.entity)
      const uniqueEntities = uniqBy(entities, "id")
      const entityTags = {}
      uniqueEntities.forEach((e) => {
        entityTags[e.name] = { name: e.name, tagType: StudioTagType.Entity }
      })

      self.tags.replace({
        Live: { name: "Live", tagType: StudioTagType.Live },
        Draft: { name: "Draft", tagType: StudioTagType.Draft },
        Scheduled: { name: "Scheduled", tagType: StudioTagType.Scheduled },
        ...entityTags,
        Mine: { name: "Mine", tagType: StudioTagType.CurrentUser },
      })

      return self.tags
    },
    selectTagFilter: flow(function* (tag: StudioTag) {
      if (!self.selectedTags.some((t) => t.name === tag.name)) {
        self.selectedTags.push(tag)
      }
    }),
    unselectTagFilter: flow(function* (t: StudioTag) {
      self.selectedTags.remove(t)
    }),
    unselectAllTagFilters: flow(function* () {
      self.selectedTags.clear()
    }),
  }))
  .views((self) => ({
    filterPitchesBySelectedTags(pitches: Pitch[]) {
      const isSelected = (tagType: StudioTagType) =>
        self.selectedTags.some((t) => t.tagType === tagType)

      return pitches.filter((pitch) => {
        const isEntityTagSelected = isSelected(StudioTagType.Entity)
        const isArchivedTagSelected = isSelected(StudioTagType.Archived)
        const isCurrentUserTagSelected = isSelected(StudioTagType.CurrentUser)
        const isLiveTagSelected = isSelected(StudioTagType.Live)
        const isDraftTagSelected = isSelected(StudioTagType.Draft)
        const isScheduledTagSelected = isSelected(StudioTagType.Scheduled)

        const studioPitchInfo = self.pitchStore.studioPitchInfos.get(pitch.id)

        if (self.selectedTags.length === 0) {
          return pitch.status !== PitchStatus.Archived
        }

        const passesArchivedTagFilter =
          (!isArchivedTagSelected && pitch.status !== PitchStatus.Archived) ||
          (isArchivedTagSelected && pitch.status === PitchStatus.Archived)

        const passesCurrentUserFilter =
          !isCurrentUserTagSelected || pitch.entity.id === self.sessionStore.currentUser?.id

        const passesGroupOrgFilter =
          !isEntityTagSelected || self.selectedTags.find((t) => t.name === pitch.entity.name)

        const passesLiveTagFilter = !isLiveTagSelected || pitch.status === PitchStatus.Published

        const passesDraftTagFilter = !isDraftTagSelected || pitch.status === PitchStatus.Draft

        const passesScheduledTagFilter =
          !isScheduledTagSelected ||
          (Boolean(studioPitchInfo?.scheduledUtc) && pitch.status === PitchStatus.Draft)

        return (
          passesGroupOrgFilter &&
          passesArchivedTagFilter &&
          passesCurrentUserFilter &&
          passesLiveTagFilter &&
          passesDraftTagFilter &&
          passesScheduledTagFilter
        )
      })
    },
  }))

export type StudioStore = Instance<typeof StudioStoreModel>
