import { flow, Instance, toGenerator, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withSessionStore } from "./session-store"
import { ReactionApi } from "../services/api/reaction-api"
import { ReactionModel, ReactionType } from "../models/reaction"
import { PublicUserModel } from "../models/public-user"
import { StringEnum } from "../utils"
import { withUserStore } from "./user-store"

export const PitchReactionDataModel = types
  .model("PitchReactionDataModel")
  .props({
    reactionCountsByType: types.map(types.number),
    recentUser: types.maybe(types.safeReference(PublicUserModel)),
    userReaction: types.maybe(ReactionModel),
  })
  .views((self) => ({
    get totalReactionCount() {
      return Array.from(self.reactionCountsByType.values()).reduce((acc, val) => acc + val, 0)
    },
    get reactionTypesWithAtLeastOneOccurence() {
      return Array.from(self.reactionCountsByType.entries())
        .filter(([, count]) => count > 0)
        .map(([type]) => type)
    },
  }))

export type PitchReactionData = Instance<typeof PitchReactionDataModel>

export const ReactionStoreModel = types
  .model("ReactionStore")
  .props({
    pitchReactionData: types.map(PitchReactionDataModel),
    reactionAnimation: types.maybe(StringEnum(ReactionType)),
  })
  .extend(withEnvironment)
  .extend(withSessionStore)
  .extend(withUserStore)
  .actions((self) => ({
    setReactionAnimation: flow(function* (reactionType?: ReactionType) {
      self.reactionAnimation = reactionType
    }),
    react: flow(function* ({
      pitchId,
      reactionType,
    }: {
      pitchId: string
      reactionType?: ReactionType
    }) {
      const reactionApi = new ReactionApi(self.environment.api)
      yield reactionApi.react({
        pitchId,
        reactionType,
      })
    }),
    fetchPitchReactionData: flow(function* (pitchId: string) {
      const reactionApi = new ReactionApi(self.environment.api)
      const result = yield* toGenerator(reactionApi.fetchPitchReactionData({ pitchId }))
      if (result.recentUser) {
        self.userStore.putUser(result.recentUser)
      }
      self.pitchReactionData.set(pitchId, { ...result, recentUser: result.recentUser?.id })
      return self.pitchReactionData.get(pitchId)
    }),
  }))

export type ReactionStore = Instance<typeof ReactionStoreModel>
