import { flow, getParent, Instance, IStateTreeNode, toGenerator, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withPitchStore } from "./pitch-store"
import { RootStoreModel } from "./root-store"
import { withPlaylistStore } from "./playlist-store"
import { SuggestionApi } from "../services/api/suggestion-api"

export const SuggestionStoreModel = types
  .model("Suggestion")
  .props({})
  .extend(withEnvironment)
  .extend(withPitchStore)
  .extend(withPlaylistStore)
  .actions((self) => ({
    fetchSuggestedPitches: flow(function* ({
      entityIds,
      showDrafts,
    }: {
      entityIds?: string[]
      showDrafts?: boolean
    }) {
      const suggestionApi = new SuggestionApi(self.environment.api)
      const result = yield* toGenerator(
        suggestionApi.getSuggestedPitches({
          entityIds,
          showDrafts,
        }),
      )
      return result.pitches.map((p) => ({
        pitch: self.pitchStore.putPitch(p.pitch),
        reason: p.reason,
      }))
    }),
    fetchSuggestedPlaylists: flow(function* (entityIds?: string[]) {
      const suggestionApi = new SuggestionApi(self.environment.api)
      const result = yield* toGenerator(suggestionApi.getSuggestedPlaylists(entityIds))
      return result.playlists.map((p) => ({
        playlist: self.playlistStore.putPlaylist(p.playlist),
        reason: p.reason,
      }))
    }),
    fetchSuggestedSubmissionPlaylists: flow(function* (entityIds?: string[]) {
      const suggestionApi = new SuggestionApi(self.environment.api)
      const result = yield* toGenerator(suggestionApi.getSuggestedSubmissionPlaylists(entityIds))
      return result.playlists.map((p) => ({
        playlist: self.playlistStore.putPlaylist(p.playlist),
        reason: p.reason,
      }))
    }),
  }))

export type SuggestionStore = Instance<typeof SuggestionStoreModel>
export const withSuggestionStore = (self: IStateTreeNode) => ({
  views: {
    get suggestionStore(): SuggestionStore {
      return getParent<Instance<typeof RootStoreModel>>(self).suggestionStore
    },
  },
})
