import {
  applySnapshot,
  flow,
  Instance,
  setLivelinessChecking,
  SnapshotOut,
  types,
} from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { PitchViewerStoreModel } from "./pitch-viewer-store"
import { PlaylistStoreModel } from "./playlist-store"
import { PitchPrompterStoreModel } from "./pitch-prompter-store"
import { OrganizationStoreModel } from "./organization-store"
import { OrganizationEditorStoreModel } from "./organization-editor-store"
import { SessionMembershipStoreModel } from "./session-membership-store"
import { PitchTipsStoreModel } from "./pitch-tips-store"
import { PushTokenStoreModel } from "./push-token-store"
import { PitchStoreModel } from "./pitch-store"
import { RegisterStoreModel } from "./register-store"
import { AssetStoreModel } from "./asset-store"
import { ActivityStoreModel } from "./activity-store"
import { PitchEditorStoreModel } from "./pitch-editor-store"
import { TimelineStoreModel } from "./timeline-store"
import { TimelineAssetStoreModel } from "./timeline-asset-store"
import { TimelineLayoutStoreModel } from "./timeline-layout-store"
import { ProfileStoreModel } from "./profile-store"
import { GroupEditorStoreModel } from "./group-editor-store"
import { DevStoreModel } from "./dev-store"
import { FaceDetectorStoreModel } from "./face-detector-store"
import { RegistrationCodeStoreModel } from "./registration-code-store"
import { ActivitySubscriptionStoreModel } from "./activity-subscription-store"
import { PaymentsStoreModel } from "./payments-store"
import { PostStoreModel } from "./post-store"
import { ResourceSearchStoreModel } from "./resource-search-store"
import { AffiliationStoreModel } from "./affiliation-store"
import { AppConfigStoreModel } from "./app-config-store"
import { AttachmentStoreModel } from "./attachment-store"
import { BlockedUserStoreModel } from "./blocked-user-store"
import { BranchLinkStoreModel } from "./branch-link-store"
import { ContentReportStoreModel } from "./content-report-store"
import { EntityStoreModel } from "./entity-store"
import { FeatureFlagStoreModel } from "./feature-flag-store"
import { GroupStoreModel } from "./group-store"
import { HomeStoreModel } from "./home-store"
import { InvitationStoreModel } from "./invitation-store"
import { LibraryStoreModel } from "./library-store"
import { MembershipRequestStoreModel } from "./membership-request-store"
import { MessagingStoreModel } from "./messaging-store"
import { NotificationSettingsStoreModel } from "./notification-settings-store"
import { SessionStoreModel } from "./session-store"
import { ShareSearchStoreModel } from "./share-search-store"
import { StudioStoreModel } from "./studio-store"
import { ThemeStoreModel } from "./theme-store"
import { UserStoreModel } from "./user-store"
import { UserAffiliationStoreModel } from "./user-affiliation-store"
import { ContentPermissionStoreModel } from "./content-permission-store"
import { UserPreferencesStoreModel } from "./user-preferences-store"
import { UserPaymentsStoreModel } from "./user-payments-store"
import { RecentContentStoreModel } from "./recent-content-store"
import { ProgramStoreModel } from "./program-store"
import { ProgramWizardStoreModel } from "./program-wizard-store"
import { AssignmentStoreModel } from "./assignment-store"
import { AssignmentUserStoreModel } from "./assignment-user-store"
import { PitchIntentStoreModel } from "./pitch-intent-store"
import { SuggestionStoreModel } from "./suggestion-store"
import { AnalyticsStoreModel } from "./analytics-store"
import { MobileSettingsStoreModel } from "./mobile-settings-store"
import { ReactionStoreModel } from "./reaction-store"
import { PitchStatsStoreModel } from "./pitch-stats-store"

// Mostly we have only gotten warnings while components are temporarily holding
// on to old models as they're being replaced. As long as we use safeReferences,
// there don't seem to be real concerns with this.
setLivelinessChecking("ignore")

export const RootStoreModel = types
  .model("RootStore")
  .props({
    appConfigStore: types.optional(AppConfigStoreModel, {}),
    activityStore: types.optional(ActivityStoreModel, {}),
    activitySubscriptionStore: types.optional(ActivitySubscriptionStoreModel, {}),
    affiliationStore: types.optional(AffiliationStoreModel, {}),
    analyticsStore: types.optional(AnalyticsStoreModel, {}),
    assetStore: types.optional(AssetStoreModel, {}),
    assignmentStore: types.optional(AssignmentStoreModel, {}),
    assignmentUserStore: types.optional(AssignmentUserStoreModel, {}),
    attachmentStore: types.optional(AttachmentStoreModel, {}),
    blockedUserStore: types.optional(BlockedUserStoreModel, {}),
    branchLinkStore: types.optional(BranchLinkStoreModel, {}),
    contentReportStore: types.optional(ContentReportStoreModel, {}),
    contentPermissionStore: types.optional(ContentPermissionStoreModel, {}),
    devStore: types.optional(DevStoreModel, {}),
    entityStore: types.optional(EntityStoreModel, {}),
    faceDetectorStore: types.optional(FaceDetectorStoreModel, {}),
    featureFlagStore: types.optional(FeatureFlagStoreModel, {}),
    groupEditorStore: types.optional(GroupEditorStoreModel, {}),
    groupStore: types.optional(GroupStoreModel, {}),
    homeStore: types.optional(HomeStoreModel, {}),
    invitationStore: types.optional(InvitationStoreModel, {}),
    libraryStore: types.optional(LibraryStoreModel, {}),
    membershipRequestStore: types.optional(MembershipRequestStoreModel, {}),
    messagingStore: types.optional(MessagingStoreModel, {}),
    mobileSettingsStore: types.optional(MobileSettingsStoreModel, {}),
    notificationSettingsStore: types.optional(NotificationSettingsStoreModel, {}),
    organizationEditorStore: types.optional(OrganizationEditorStoreModel, {}),
    organizationStore: types.optional(OrganizationStoreModel, {}),
    paymentsStore: types.optional(PaymentsStoreModel, {}),
    pitchEditorStore: types.optional(PitchEditorStoreModel, {}),
    pitchPrompterStore: types.optional(PitchPrompterStoreModel, {}),
    pitchStore: types.optional(PitchStoreModel, {}),
    pitchTipsStore: types.optional(PitchTipsStoreModel, {}),
    pitchViewerStore: types.optional(PitchViewerStoreModel, {}),
    playlistStore: types.optional(PlaylistStoreModel, {}),
    pitchIntentStore: types.optional(PitchIntentStoreModel, {}),
    pitchStatsStore: types.optional(PitchStatsStoreModel, {}),
    postStore: types.optional(PostStoreModel, {}),
    profileStore: types.optional(ProfileStoreModel, {}),
    programStore: types.optional(ProgramStoreModel, {}),
    programWizardStore: types.optional(ProgramWizardStoreModel, {}),
    pushTokenStore: types.optional(PushTokenStoreModel, {}),
    recentContentStore: types.optional(RecentContentStoreModel, {}),
    reactionStore: types.optional(ReactionStoreModel, {}),
    registerStore: types.optional(RegisterStoreModel, {}),
    registrationCodeStore: types.optional(RegistrationCodeStoreModel, {}),
    resourceSearchStore: types.optional(ResourceSearchStoreModel, {}),
    sessionStore: types.optional(SessionStoreModel, {}),
    sessionMembershipStore: types.optional(SessionMembershipStoreModel, {}),
    shareSearchStore: types.optional(ShareSearchStoreModel, {}),
    studioStore: types.optional(StudioStoreModel, {}),
    suggestionStore: types.optional(SuggestionStoreModel, {}),
    themeStore: types.optional(ThemeStoreModel, {}),
    timelineAssetStore: types.optional(TimelineAssetStoreModel, {}),
    timelineLayoutStore: types.optional(TimelineLayoutStoreModel, {}),
    timelineStore: types.optional(TimelineStoreModel, {}),
    userAffiliationStore: types.optional(UserAffiliationStoreModel, {}),
    userStore: types.optional(UserStoreModel, {}),
    userPaymentsStore: types.optional(UserPaymentsStoreModel, {}),
    userPreferencesStore: types.optional(UserPreferencesStoreModel, {}),
    // add in alphabetical order
  })
  .extend(withEnvironment)
  .actions((self) => ({
    reset: flow(function* () {
      // clear everything out
      applySnapshot(self, {})
    }),
  }))

/**
 * The RootStore instance.
 */
export type RootStore = Instance<typeof RootStoreModel>

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
