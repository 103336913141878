export const palette = {
  pureBlack: "#000000",
  black: "#1d1d1d",
  white: "#ffffff",
  mist: "#b8c7dc",
  barelyGrey: "#dbe1ec",
  offWhite: "#e6e6e6",
  angry: "#dd3333",
  blue: "#0068ff",
  orange: "#f7a11d",

  // Pitch 45
  red: "#e11d41",
  crimson: "#ae1632",
  green: "#1de141",
  forestGreen: "#16a931",
  eggplant: "#4d4364",
  burgundy: "#660000",
  silver: "#BFCDD8",
  pewterGrey: "#A0A6B3",
  slateGrey: "#4F586C",
  charcoalGrey: "#181d24",
  ironGrey: "#242e37",
  gunmetal: "#373D4D",

  // analytics blues
  darkBlue: "#066099",
  mediumBlue: "#339EC0",
  lightBlue: "#6BE8F0",
}
