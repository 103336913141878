import {
  flow,
  getParent,
  Instance,
  IStateTreeNode,
  toGenerator,
  types,
  getRoot,
} from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { SessionApi } from "../services/api/session-api"
import { UserApi } from "../services/api/user-api"
import { AuthApi } from "../services/api/auth-api"
import { SsoConfigApi } from "../services/api/sso-config-api"
import { withUserStore } from "./user-store"
import { withOrganizationStore } from "./organization-store"
import logger from "../logging/logger"
import { OAuthTokenResponse } from "../utils/oauth"
import { RootStore, RootStoreModel } from "./root-store"
import { withUserAffiliationStore } from "./user-affiliation-store"
import { getApiErrorProblemKind, getAxiosErrorCode, getErrorMessage } from "../utils/error"
import { jwtDecode } from "jwt-decode"
import { Permission, Session, SessionModel } from "../models/session"
import { PersonalUser, PersonalUserModel } from "../models/personal-user"

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const LoginRedirectRouteModel = types.model("LoginRedirectRoute").props({
  name: types.string,
  params: types.optional(types.frozen(), {}),
})

interface JwtPayload {
  mfa_enabled?: string
  amr: string[]
}

export const SessionStoreModel = types
  .model("SessionStore")
  .props({
    session: types.optional(SessionModel, {}),
    currentUser: types.maybeNull(PersonalUserModel),
    hasAccessToken: types.optional(types.boolean, false),
    mfaEnabled: types.optional(types.boolean, false),
    loginRedirectRoute: types.maybe(LoginRedirectRouteModel),
    pendingInviteId: types.maybe(types.string),
    pendingRegistrationCodeId: types.maybe(types.string),
  })
  .extend(withEnvironment)
  .extend(withUserStore)
  .extend(withOrganizationStore)
  .extend(withUserAffiliationStore)
  .actions((self) => ({
    // using async instead of flow because we need to call it from setup-root-store where there is no parent context
    setAuthorization: async function (
      accessToken: string | undefined,
      refreshToken: string | undefined,
    ) {
      if (accessToken) {
        self.environment.api.apisauce.setHeader("Authorization", `Bearer ${accessToken}`)
        const decoded = jwtDecode<JwtPayload>(accessToken, { header: false })
        if (decoded.mfa_enabled) {
          self.mfaEnabled = true
          if (decoded.amr.includes("mfa")) {
            self.hasAccessToken = true
          } else {
            self.hasAccessToken = false
          }
        } else {
          self.mfaEnabled = false
          self.hasAccessToken = true
        }
      } else {
        self.environment.api.apisauce.deleteHeader("Authorization")
        self.hasAccessToken = false
        self.mfaEnabled = false
      }

      if (self.environment.hasSecureStorage) {
        if (accessToken) {
          await self.environment.secureStorage.setItemAsync("access-token", accessToken)
        } else {
          await self.environment.secureStorage.deleteItemAsync("access-token")
        }

        if (refreshToken) {
          await self.environment.secureStorage.setItemAsync("refresh-token", refreshToken)
        } else {
          await self.environment.secureStorage.deleteItemAsync("refresh-token")
        }
      }
    },
    saveLastLoggedInEmail: (email: string) => {
      return self.environment.storage.saveString("lastLoggedInEmail", email)
    },
    loadLastLoggedInEmail: () => {
      return self.environment.storage.loadString("lastLoggedInEmail")
    },
    setSession: (session: Session) => {
      if (session === null) {
        throw new Error("Cannot set a null session")
      }
      self.session = session
    },
    setCurrentUser: (user: PersonalUser) => {
      self.currentUser = user
      logger.identify(user.id)
      return self.currentUser
    },
    setLoginRedirectRoute: (name: string, params: any) => {
      self.loginRedirectRoute = {
        name,
        params,
      }
    },
    clearLoginRedirectRoute: () => {
      self.loginRedirectRoute = undefined
    },
    setPendingInviteId: (inviteId: string) => {
      self.pendingInviteId = inviteId
    },
    setPendingRegistrationCodeId: (registrationCodeId: string) => {
      self.pendingRegistrationCodeId = registrationCodeId
    },
    clearPendingInviteId: flow(function* () {
      self.pendingInviteId = undefined
      yield self.environment.secureStorage.deleteItemAsync("pendingInviteId")
    }),
    clearPendingRegistrationCodeId: flow(function* () {
      self.pendingRegistrationCodeId = undefined
      yield self.environment.secureStorage.deleteItemAsync("pendingRegistrationCodeId")
    }),
  }))
  .actions((self) => ({
    saveInvitationTokens: flow(function* () {
      if (!self.environment.hasSecureStorage) {
        return
      }
      if (self.pendingInviteId) {
        yield self.environment.secureStorage.setItemAsync("pendingInviteId", self.pendingInviteId)
      }
      if (self.pendingRegistrationCodeId) {
        yield self.environment.secureStorage.setItemAsync(
          "pendingRegistrationCodeId",
          self.pendingRegistrationCodeId,
        )
      }
    }),
    clearInvitationTokens: flow(function* () {
      if (!self.environment.hasSecureStorage) {
        return
      }
      yield self.environment.secureStorage.deleteItemAsync("pendingInviteId")
      yield self.environment.secureStorage.deleteItemAsync("pendingRegistrationCodeId")
    }),
    restoreInvitationTokens: flow(function* () {
      if (!self.environment.hasSecureStorage) {
        return
      }
      const pendingInviteId = yield self.environment.secureStorage.getItemAsync("pendingInviteId")
      if (pendingInviteId) {
        self.setPendingInviteId(pendingInviteId)
      }

      const pendingRegistrationCodeId = yield self.environment.secureStorage.getItemAsync(
        "pendingRegistrationCodeId",
      )
      if (pendingRegistrationCodeId) {
        self.setPendingRegistrationCodeId(pendingRegistrationCodeId)
      }
    }),
  }))
  .actions((self) => ({
    logout: flow(function* () {
      let refreshToken: string | null = null
      if (self.environment.hasSecureStorage) {
        refreshToken = yield* toGenerator(
          self.environment.secureStorage.getItemAsync("refresh-token"),
        )
      }

      // revoke even without a refresh token, since this is what kicks off web log out
      yield self.environment.api.oauthProvider.revoke(refreshToken)

      const rootStore = getRoot<RootStore>(self)
      logger.identify(null)

      try {
        // perform any app-specific logout behavior, but don't let it block core logout
        self.environment.onLogout(rootStore)
      } catch (e) {
        logger.logError(e)
      }

      yield self.setAuthorization(undefined, undefined)

      // wait to reset the store because onLogout resets navigation, but the current
      // screen won't be unmounted until the navigation transition completes and if
      // we reset the store it will re-render without data it may be depending on
      yield new Promise<void>((resolve) => {
        setTimeout(() => {
          rootStore.reset()
          resolve()
        }, 1000)
      })
    }),
  }))
  .actions((self) => ({
    fetchLoggedInSession: flow(function* () {
      const sessionApi = new SessionApi(self.environment.api)
      const result = yield* toGenerator(sessionApi.getLoggedIn())
      self.setSession(result.session)
      return true
    }),
    getSsoLoginUrl: flow(function* ({ email }) {
      yield self.setAuthorization(undefined, undefined) // make sure to clear any errant tokens before logging in
      const ssoConfigApi = new SsoConfigApi(self.environment.api)
      return yield* toGenerator(ssoConfigApi.getSsoLoginUrl({ email }))
    }),
    login: flow(function* ({ email, password, mfaCode }) {
      yield self.setAuthorization(undefined, undefined) // make sure to clear any errant tokens before logging in
      const authApi = new AuthApi(self.environment.authApi)
      const result = yield* toGenerator(
        authApi.requestResourceOwnerPasswordGrant({ email, password, mfaCode }),
      )

      yield self.setAuthorization(result.accessToken, result.refreshToken)
    }),
    supportLogin: flow(function* ({ password, supportRequestId }) {
      yield self.setAuthorization(undefined, undefined) // make sure to clear any errant tokens before logging in
      const authApi = new AuthApi(self.environment.authApi)
      const result = yield* toGenerator(
        authApi.supportRequestResourceOwnerPasswordGrant({ password, supportRequestId }),
      )

      yield self.setAuthorization(result.accessToken, undefined)
    }),
    /**
     * Used to initiate the silent auth code flow
     */
    oauthAuthorize: flow(function* () {
      yield self.setAuthorization(undefined, undefined) // make sure to clear any errant tokens before logging in
      const result = yield* toGenerator(self.environment.api.oauthProvider.authorize())
      yield self.setAuthorization(result.accessToken, result.refreshToken)
      return true
    }),
    oauthRefresh: flow(function* () {
      const refreshToken = yield* toGenerator(
        self.environment.secureStorage.getItemAsync("refresh-token"),
      )

      let result: OAuthTokenResponse | undefined

      if (refreshToken) {
        let retryCount = 0
        while (retryCount++ < 5) {
          try {
            result = yield* toGenerator(self.environment.api.oauthProvider.refresh(refreshToken))
            logger.log("Successfully refreshed token")
            break
          } catch (e) {
            if (
              getAxiosErrorCode(e) === "invalid_grant" ||
              getErrorMessage(e) === "invalid_grant"
            ) {
              logger.logWarning("Refresh token has expired")
              break
            }
            // any other error means there is an issue with the auth server, not the refresh token,
            // so give it a chance to heal
            else {
              logger.logError(new Error("Error refreshing token"), { originalError: e })
            }
          }

          // exponential backoff, up to ~15s
          yield timeout(500 * Math.pow(2, retryCount))
        }

        if (result?.accessToken) {
          yield self.setAuthorization(result.accessToken, result.refreshToken)
        }
      }

      if (result?.accessToken) {
        return result.accessToken
      } else {
        // save invites and registration codes so we can use them after the user logs in
        // only do this in a forced logout scenario
        yield self.saveInvitationTokens()
        self.logout() // initiate the logout process if we can't get a new access token
        return undefined
      }
    }),
    fetchCurrentUser: flow(function* () {
      const userApi = new UserApi(self.environment.api)
      const result = yield* toGenerator(userApi.getCurrentUser())
      return self.setCurrentUser(result.user)
    }),
  }))
  .actions((self) => ({
    signup: flow(function* ({
      firstName,
      lastName,
      email,
      password,
      pendingInviteId,
      pendingRegistrationCodeId,
    }) {
      yield self.setAuthorization(undefined, undefined) // make sure to clear any errant tokens before logging in
      const sessionApi = new SessionApi(self.environment.api)
      try {
        yield sessionApi.signup({
          firstName,
          lastName,
          email,
          password,
          pendingInviteId: pendingInviteId ?? self.pendingInviteId,
          pendingRegistrationCodeId: pendingRegistrationCodeId ?? self.pendingRegistrationCodeId,
        })
      } catch (err) {
        if (getApiErrorProblemKind(err)) {
          // A conflict occurs when the user signs up with the same creds as an already logged in user. In that
          // case we can just skip ahead and log them in.
          if (getApiErrorProblemKind(err) !== "conflict") {
            throw err
          }
        }
      }

      // log in after signing up
      yield self.login({ email, password })
    }),
    /**
     * Logs in and sets cookies on the auth domain so that the silent renew flow can be used
     */
    authDomainLogin: flow(function* ({ email, password, mfaCode }) {
      yield self.setAuthorization(undefined, undefined) // make sure to clear any errant tokens before logging in
      const authApi = new AuthApi(self.environment.authApi)
      yield* toGenerator(authApi.authDomainLogin({ email, password, mfaCode }))
    }),
    authDomainSupportLogin: flow(function* ({ email, password, supportRequestId }) {
      yield self.setAuthorization(undefined, undefined)
      const authApi = new AuthApi(self.environment.authApi)
      yield* toGenerator(authApi.authDomainSupportLogin({ email, password, supportRequestId }))
    }),
  }))
  .views((self) => ({
    hasPermission(entityId: string | null | undefined, permission: Permission): boolean {
      const entityPermissions = self.session?.entityPermissions.get(entityId)
      return Boolean(entityPermissions?.some((p) => p === permission))
    },
    getEntitiesWithPermission(permission: Permission) {
      return Array.from(self.session?.entityPermissions.keys() || []).filter((entityId) =>
        self.session?.entityPermissions.get(entityId)?.includes(permission),
      )
    },
  }))

export type SessionStore = Instance<typeof SessionStoreModel>
export const withSessionStore = (self: IStateTreeNode) => ({
  views: {
    get sessionStore(): SessionStore {
      return getParent<Instance<typeof RootStoreModel>>(self).sessionStore
    },
  },
})
