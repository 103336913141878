import { flow, Instance, toGenerator, types, cast } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { withGroupStore } from "../stores/group-store"
import { PaymentsApi } from "../services/api/payments-api"
import { NIL } from "uuid"
import {
  SubscriptionPlan,
  SubscriptionPlanModel,
  EntitySubscriptionModel,
  PaymentProfileModel,
  BillingInfoModel,
  BillingFormModel,
  InvoiceModel,
  BillingInfo,
  BillingInterval,
} from "../models/payments"
import { createField } from "../models/form/string-field"

const FREE_PLAN: SubscriptionPlan = {
  billingInterval: BillingInterval.Month,
  id: NIL,
  name: "Free Plan",
  planType: "organization",
  price: 0,
  usagePrice: 0,
}

export const PaymentsStoreModel = types
  .model("PaymentsStore")
  .props({
    subscriptionPlans: types.map(SubscriptionPlanModel),
    entitySubscriptions: types.map(EntitySubscriptionModel),
    paymentProfiles: types.map(PaymentProfileModel),
    billingInfos: types.map(BillingInfoModel),
    billingForm: types.maybe(BillingFormModel),
    upcomingInvoices: types.map(InvoiceModel),
  })
  .extend(withEnvironment)
  .extend(withGroupStore)
  .actions((self) => ({
    populateBillingForm: (billingInfo?: BillingInfo) => {
      self.billingForm = cast({
        name: createField("name", billingInfo?.name),
        email: createField("email", billingInfo?.email),
        line1: createField("line1", billingInfo?.line1),
        line2: createField("line2", billingInfo?.line2),
        city: createField("city", billingInfo?.city),
        state: createField("state", billingInfo?.state),
        postalCode: createField("postalCode", billingInfo?.postalCode),
      })
    },
    saveBillingInfo: flow(function* (entityId) {
      const api = new PaymentsApi(self.environment.api)
      if (!self.billingForm?.name.value || !self.billingForm?.email.value) {
        throw new Error("Name and email are required")
      }
      const valuesToSave = {
        name: self.billingForm?.name.value,
        email: self.billingForm?.email.value,
        line1: self.billingForm?.line1.value,
        line2: self.billingForm?.line2.value,
        city: self.billingForm?.city.value,
        state: self.billingForm?.state.value,
        postalCode: self.billingForm?.postalCode.value,
      }
      yield api.createCustomer(entityId, valuesToSave)
    }),
    updateBillingInfo: flow(function* (entityId) {
      const api = new PaymentsApi(self.environment.api)
      if (!self.billingForm?.name.value || !self.billingForm?.email.value) {
        throw new Error("Name and email are required")
      }
      const valuesToSave = {
        name: self.billingForm?.name.value,
        email: self.billingForm?.email.value,
        line1: self.billingForm?.line1.value,
        line2: self.billingForm?.line2.value,
        city: self.billingForm?.city.value,
        state: self.billingForm?.state.value,
        postalCode: self.billingForm?.postalCode.value,
      }
      yield api.updateCustomer(entityId, valuesToSave)
    }),
    resetBillingForm() {
      self.billingForm = undefined
    },
    cancelSubscription: flow(function* (entityId: string) {
      const api = new PaymentsApi(self.environment.api)
      yield api.deleteSubscription(entityId)
    }),
  }))
  .views((self) => ({
    getEntitySubscription(entityId) {
      return Array.from(self.entitySubscriptions.values()).find((s) => s?.entityId === entityId)
    },
  }))
  .actions((self) => ({
    fetchBillingInfo: flow(function* (entityId) {
      const api = new PaymentsApi(self.environment.api)
      const result = yield* toGenerator(api.getBillingInfo(entityId))
      if (result.billingInfo) {
        self.billingInfos.set(entityId, result.billingInfo)
      }
      return true
    }),
    fetchPaymentProfile: flow(function* (entityId) {
      const api = new PaymentsApi(self.environment.api)
      const result = yield* toGenerator(api.getPaymentProfile(entityId))
      if (result.paymentProfile) {
        self.paymentProfiles.put(result.paymentProfile)
      } else {
        for (const paymentProfile of self.paymentProfiles.values()) {
          if (paymentProfile?.entityId === entityId) {
            self.paymentProfiles.delete(paymentProfile?.id)
          }
        }
      }
      return true
    }),
    fetchEntitySubscription: flow(function* (entityId) {
      const api = new PaymentsApi(self.environment.api)
      const result = yield* toGenerator(api.getEntitySubscription(entityId))
      if (result.entitySubscription) {
        self.entitySubscriptions.put(result.entitySubscription)
      } else {
        for (const entitySubscriptions of self.entitySubscriptions.values()) {
          if (entitySubscriptions?.entityId === entityId) {
            self.entitySubscriptions.delete(entitySubscriptions?.id)
          }
        }
      }

      if (result.upcomingInvoice) {
        self.upcomingInvoices.set(entityId, result.upcomingInvoice)
      } else {
        self.upcomingInvoices.delete(entityId)
      }

      // need to return subscription because we check if the subscription is manually entitled
      // in order to display modal
      return self.getEntitySubscription(entityId)
    }),
    fetchSubscriptionPlans: flow(function* (entityId) {
      const api = new PaymentsApi(self.environment.api)
      const result = yield* toGenerator(api.getSubscriptionPlans(entityId))
      self.subscriptionPlans.clear()
      return result.subscriptionPlans.map((sp) => self.subscriptionPlans.put(sp))
    }),
    fetchDefaultOrgSubscriptionPlan: flow(function* () {
      const api = new PaymentsApi(self.environment.api)
      const result = yield* toGenerator(api.getDefaultOrgSubscriptionPlan())
      return self.subscriptionPlans.put(result.subscriptionPlan)
    }),
    fetchHasValidEntitySubscription: flow(function* (orgId: string) {
      const api = new PaymentsApi(self.environment.api)
      return yield* toGenerator(api.getHasValidEntitySubscription(orgId))
    }),
  }))
  .views((self) => ({
    getPaymentProfile(entityId) {
      return Array.from(self.paymentProfiles.values()).find((p) => p?.entityId === entityId)
    },
    getSubscriptionPlans() {
      return Array.from(self.subscriptionPlans.values())
    },
  }))
  .views((self) => ({
    getCurrentPlan(entityId): SubscriptionPlan | undefined {
      const entitySubscription = self.getEntitySubscription(entityId)
      if (entitySubscription && self.subscriptionPlans) {
        return self.subscriptionPlans.get(entitySubscription.planId)
      } else {
        return FREE_PLAN
      }
    },
  }))

export type PaymentsStore = Instance<typeof PaymentsStoreModel>
