import { Instance, types } from "mobx-state-tree"
import { EntityModel } from "./entity"
import { DateTime } from "../utils/datetime-type"
import { ContentVisibility } from "./content-visibility"
import { StringEnum } from "../utils/string-enum-type"
import { AutoPostType } from "./auto-post-type"
import { PitchModel } from "./pitch"
import { PublicUserModel } from "./public-user"

export enum PlaylistType {
  Playlist = "Playlist",
  Bookmarks = "Bookmarks",
  Profile = "Profile",
  PeerToPeer = "PeerToPeer",
}

export enum SamplePlaylistType {
  ProfileVideoTips = "ProfileVideoTips",
  SampleVideos = "SampleVideos",
}

export interface PlaylistParams {
  id?: string
  name?: string
  description?: string
  coverAssetId?: string
  closedUtc?: Date
  visibility?: ContentVisibility | string
  autoPostType?: AutoPostType | string
  entityId?: string
}

export const ScheduledPlaylistPitchModel = types.model("ScheduledPlaylistPitch").props({
  pitch: types.safeReference(PitchModel),
  scheduledUtc: DateTime,
})

/**
 * Playlist model.
 */
export const PlaylistModel = types.model("Playlist").props({
  name: types.maybe(types.string),
  id: types.identifier,
  entity: EntityModel,
  coverAssetId: types.maybe(types.string),
  type: types.maybe(StringEnum(PlaylistType)),
  visibility: types.optional(StringEnum(ContentVisibility), ContentVisibility.Private),
  autoPostType: types.optional(StringEnum(AutoPostType), AutoPostType.Off),
  pitchCount: types.optional(types.number, 0),
  thumbnailAssetIds: types.maybe(types.array(types.string)),
  createdUtc: DateTime,
  updatedUtc: DateTime,
  description: types.maybe(types.string),
  lastSubmittedUtc: DateTime,
  closedUtc: DateTime,
  qrCodeAssetId: types.maybe(types.string),
  autogeneratedCoverAssetId: types.maybe(types.string),
  autogeneratedImageName: types.maybe(types.string),
  userId: types.maybe(types.string),
  user: types.maybe(types.safeReference(PublicUserModel)),
})

export type Playlist = Instance<typeof PlaylistModel>
