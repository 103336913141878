import React, { useState } from "react"
import { CSSObject, useTheme } from "@emotion/react"
import { Input } from "@mui/material"
import { WebAppTheme } from "pitch45-common/theme/app-theme.types"
import { Text } from "../text/text"
import { typography } from "pitch45-common/theme/typography"
import { color } from "../../theme/color"
import Icon, { Icons } from "../icon/icon"
import { textPresets } from "../text/text.presets"
import { semanticSpacing, unitSpacing } from "../../theme/spacing"
import { fontSizing } from "../../theme"

export interface TextInputProps {
  value: string | string[]
  label?: string
  placeholder?: string
  error?: string
  onChangeText: (value: string) => void
  onBlur?: (event: any) => void
  disabled?: boolean
  type?: string
  minWidth?: number
  maxWidth?: number
  fullWidth?: boolean
  onKeyDown?: (string) => void
  autoFocus?: boolean
  descriptionLabel?: string
  endIcon?: Icons
  iconAction?: () => void
  multiline?: boolean
  maxRows?: number
  minRows?: number
  maxCharacterCount?: number
  autoCapitalize?: "on" | "off"
  autoCorrect?: "on" | "off"
}

function getStyles(theme: WebAppTheme) {
  const styles = {
    INPUT: {
      ...typography.regular, // TODO: Figure out why calling theme is causing runtime errors.  Had to remove theme to render font correctly.
      background: theme.colors?.background,
      color: theme.colors?.text,
      padding: `${unitSpacing.half} ${unitSpacing.quarter}`,
      includeFontPadding: false,
      fontSize: fontSizing.medium,
      border: "none",
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      borderBottomColor: theme.colors?.dimText,
      outline: "none",
      flexGrow: 1,
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: color.dimText,
      },
      "& .MuiInput-root": {
        ...typography.regular,
      },
    },
    CONTAINER: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      margin: semanticSpacing.vertical.narrow,
    },
    INPUT_CONTAINER: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      paddingLeft: unitSpacing.half,
      borderLeftStyle: "solid",
      borderLeftWidth: 4,
      borderLeftColor: theme.colors?.dimText,
    },
    LABEL: {
      display: "flex",
    },
    ERROR_MESSAGE: {
      color: theme.colors?.error,
      marginTop: unitSpacing.half,
    },
    SUBSCRIPT_LABEL: {
      marginTop: unitSpacing.half,
    },
    CHARACTER_COUNT: {
      ...theme.webTypography.light,
      color: theme.colors?.dimText,
      fontSize: 14,
      width: "100%",
      marginTop: unitSpacing.half,
      paddingLeft: unitSpacing.halfPlus,
      textAlign: "left",
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

export function TextInput(props: TextInputProps) {
  const {
    autoFocus,
    value,
    label,
    placeholder,
    error,
    onChangeText,
    onBlur,
    disabled,
    type = "text",
    minWidth,
    maxWidth,
    fullWidth,
    onKeyDown,
    descriptionLabel,
    multiline,
    maxRows,
    minRows,
    maxCharacterCount,
    autoCapitalize = "on",
    autoCorrect = "on",
    ...rest
  } = props

  const theme = useTheme() as WebAppTheme
  const [showPassword, setShowPassword] = useState(false)
  const {
    CONTAINER,
    INPUT,
    ERROR_MESSAGE,
    INPUT_CONTAINER,
    LABEL,
    SUBSCRIPT_LABEL,
    CHARACTER_COUNT,
  } = getStyles(theme)

  return (
    <div css={CONTAINER} {...rest}>
      <div css={INPUT_CONTAINER}>
        {label && (
          <Text preset={textPresets.medium} css={LABEL}>
            {label}:
          </Text>
        )}
        <Input
          autoFocus={autoFocus}
          css={[INPUT, disabled && { color: theme.colors.primary }]}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChangeText(e.target.value)}
          type={type === "password" && showPassword ? "text" : type}
          disabled={disabled}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          fullWidth={fullWidth}
          inputProps={{
            minLength: minWidth,
            maxLength: maxWidth,
            style: {
              color: theme.colors.text,
              ...typography.regular,
            },
            autoCapitalize: autoCapitalize,
            autoCorrect: autoCorrect,
          }}
          disableUnderline
          multiline={multiline}
          maxRows={maxRows}
          minRows={minRows}
        />
        {type === "password" && (
          <Icon
            icon={showPassword ? Icons.Visibility : Icons.VisibilityOff}
            onClick={() => setShowPassword(!showPassword)}
            css={{
              position: "absolute",
              color: theme.colors.dimText,
              right: "10px",
              cursor: "pointer",
            }}
          />
        )}
      </div>
      {maxCharacterCount && (
        <Text
          preset={textPresets.medium}
          css={[CHARACTER_COUNT, value?.length > maxCharacterCount && { color: color.primary }]}
        >
          {value?.length || 0} / {maxCharacterCount}
        </Text>
      )}
      {descriptionLabel && (
        <Text preset={textPresets.small} css={SUBSCRIPT_LABEL}>
          {descriptionLabel}
        </Text>
      )}
      {error && (
        <Text preset={textPresets.small} css={ERROR_MESSAGE}>
          {error}
        </Text>
      )}
    </div>
  )
}

export default TextInput
